<template lang="pug">
	section#galeriaVideo
		h3 Galeria de Videos
		.gallery
			.videoContainer
				iframe(:src="items[ativo].link" frameBorder="0" allowfullscreen="1").active
			ul(v-if="items.length > 1")
				li(v-for="item, index in items" :class="{'ativo' : index == ativo}" @click="toggleVideo(index)" v-if="index !== ativo" :title="item.title")
					.image
						img(:src="item.image")
					.text
						p {{ item.title }}
</template>

<script>
import { props } from '@/mixins/component'

export default {
	name: 'galeria-video',
	props,
	data() {
		return {
			ativo: 0,
			items: [],
		}
	},
	methods: {
		toggleVideo(index) {
			this.ativo = index
		}
	},
    mounted() {
		this.items = this.contents.content.map(item => ({
			...item,
			image: `${process.env.VUE_APP_FILE_API_ROUTE}/medias/media/${item.image}`
		}))
	}
}
</script>

<style lang="stylus" scoped src="./GaleriaVideo.styl">

</style>